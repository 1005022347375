import React from 'react'
import { Slider, Slide} from 'react-materialize'
import MySlide1 from './slide1'
import MySlide2 from './slide2'
import MySlide3 from './slide3'
import MySlide4 from './slide4'
import MySlide5 from './slide5'
import MySlide6 from './slide6'
import MySlide7 from './slide7'
import MySlide8 from './slide8'
import MySlide9 from './slide9'

const Slider1 = () => {
  return (
    <>
    <Slider className="hide-on-med-and-down" options={{height: 400, indicators: false}}>
      <Slide image={<MySlide1 className="animate" />} />
      <Slide image={<MySlide2 className="animate" />} />
      <Slide image={<MySlide3 className="animate" />} />
      <Slide image={<MySlide4 className="animate" />} />      
      <Slide image={<MySlide5 className="animate" />} />      
      <Slide image={<MySlide6 className="animate" />} />      
      <Slide image={<MySlide7 className="animate" />} />      
      <Slide image={<MySlide8 className="animate" />} />      
      <Slide image={<MySlide9 className="animate" />} />      
    </Slider>
    <Slider className="hide-on-large-only" options={{height: 300, indicators: false}}>
      <Slide image={<MySlide1 className="animate" />} />
      <Slide image={<MySlide2 className="animate" />} />
      <Slide image={<MySlide3 className="animate" />} />
      <Slide image={<MySlide4 className="animate" />} />      
      <Slide image={<MySlide5 className="animate" />} />      
      <Slide image={<MySlide6 className="animate" />} />      
      <Slide image={<MySlide7 className="animate" />} />      
      <Slide image={<MySlide8 className="animate" />} />      
      <Slide image={<MySlide9 className="animate" />} />      
    </Slider>
    </>
  )
}

export default Slider1