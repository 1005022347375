import React, {useRef, useEffect} from "react"
import HeaderAbout from "../components/headerAbout"
import SEO from "../components/seo"
import Image from "../components/aboutImg1"
import { gsap } from 'gsap'
import Slider1 from '../components/slider'
import { Link } from "gatsby"
import Layout from "../components/layout"


const About = () => {
  let doctorText = useRef(null)

  useEffect(() => {
    gsap.from(doctorText.children, {duration: 1, y: 35, ease: "back", opacity: 0, stagger: 0.1 })

  }, [])

  return(
  <Layout>
    <SEO title="Gyn&eacute;cologie - Obst&eacute;trique - OBGYN - Sant&eacute; - Haiti - Contact" />
    <HeaderAbout />
    <div className="pages-body">
      <section className="about-info color-1">
        <div className="doctor">
          <Image className="doctor-image" />
          <div ref={el => doctorText = el} className="doctor-text white-text">
            <h3 className="center color-3-text">Dr Garry Ren&eacute;</h3>
            <h4>Langue</h4>
            <p>Créole, Francais, Anglais, Espagnol</p>
            <h4>&Eacute;tudes M&eacute;dicales</h4>
            <p>-Diplome de Docteur en Médecine (UEH,1983-1990)</p>
            <p>-Licence de Docteur en Médecine (MSPP, 1991)</p>
            <p>-Spécialisaton en Obstétrique et Gynécologie (HUEH, 1991-1994)</p>
            <p>-Diplome de spécialiste en AMP (Assistance Médicale Procréation: IVF-ICSI) (Hopital A. Beclère, Paris, France, 2000-2001</p>
            <p>-Stage de formation en insémination artificielle, fécondation in vitro, ICSI (Intra Cytoplasmic Sperm Injection) (Clinique Ste Marie, Fort de France, Martinique, 2002-2004</p>
            <p>-Séminaires de Formation continue en Imagerie Gynécologique et Obstérical, ultra-sonographie, techniques avancées en OBGYN (ANTECHO et J.T.A) (Martinique et Guadeloupe, 2002-2009)</p>
            <h4>Expériences Professionnelles</h4>
            <p>-Médecin OBGYN: responsable de la Maternité de l'Hôpital Ste Catherine Laboure, Cité Soleil (1994-1995)</p>
            <p>-Médecin OBGYN: responsable de la Clinique Jeunes FOSREF-Christ-Roi (1995-1996)</p>
            <p>-Médecin OBGYN: responsable du service échographie à PROFAMIL (1996-1997)</p>
            <p>-Médecin OBGYN: responsable de la Maternité Hôpital Jude Anne (1997-1998)</p>
            <p>-Médecin OBGYN attaché au service d'urgence de l'Hôpital du Canapé Vert (1998-2000)</p>
            <p>-Clinique privée à Polyclinique Joseph Janvier (1995-1999)</p>
            <p>-Clinique privée: UNIMED (Delmas 34) (1999-2006)</p>
            <p>-Clinique privée: Clinique Louverture (Pétion Ville) (2004-2006)</p>
            <p>-PDG de Villa Sante de Montaigne Noire (Clinique de soins complets pour la femme) (2006 à nos jours)</p>
            <p>-Chef de service au programme OBGYN au CDTI-Hôpital du Sacré Coeur (2007-2010)</p>
            <p>-Chef de programme d'OBGYN À Lambert Santé Hôpital (2011 à nos jours)</p>
            <p>-OBGYN à MEDCENTER (2012 à nos jours)</p> 
            <h4>Enseignement</h4>
            <p>-Médecin OBGYN responsable de l'éducation sexuelle pour les écoles de la CEEC (Commission Épiscopale pour Éducation Catholique) (2002-2004)</p>
            <p>-Professeur d'OBGYN à l'Université Royale (2002-2003)</p>
            <p>-Médecin OBGYN animateur de l'émission: Le Sexe au 20eme Siè à Radio SIGNAL FM (2002-2005)</p>
            <p>-Médecin OBGYN animateur de l'émission: La SANTÉ c'est la VIE à Radio VISION 2000 (2006 à nos jours)</p>
            <h4>Publications</h4>
            <p>-Les abcès tubo-ovariens Post abortum (HUEH) (1993)</p>
            <p>-Mother-Baby Package Soins prénataux mère-enfant (MEEH) (1995)</p>
            <p>-Laminaires et Pre-eclampsie sévère (CHOSCAL) (1996)</p>
            <p>-Indications de ls Fécondation In Vitro (SHOG) (2001)</p>
            <p>-TORCHS et dépistage des malformations foetales (AMH) (2002)</p>
            <p>-Place de la mesure échographique du col dans menace d'accouchement preématuré (SHOG) (2003)</p>
            <p>-La mort foetal: de l'annonce au deuil (SHOG) (2004)</p>
            <p>-Le gynécologue face au harcèlement sexuel (SHOG) (2005)</p>
            <p>-Hystero-sonographie et saignements après ménopause (SHOG) (2006)</p>
            <p>-Dépistage de l'anémie foetal in utero par Doppler de l'ACM (SHOG) (2007)</p>
            <p>-Nouveautés en matière de contraception: YAZ (SHOG) (2009)</p>
            <h4>Affiliations</h4>            
            <p>-Membre de l'Association Médicale Haitienne</p>
            <p>-Membre de la Société Haitienne d'Obstétrique et de Gynécologie (SHOG)</p>
            <p>-Membre de la Fédération Latino-Américaine d'Obstétrique et de Gynécologie (FLASOG)</p>
            <p>-Membre de la Fédération Internation d'Obstétrique et de Gynécologie (FIGO)</p>
            <p>-Membre de la American Fertility Association (AFA)</p>
            <p>-Juge international de Body Building</p>
            <p>-Membre fondateur de la Société Haitienne d'Oncologie (SHONC)</p>
            <p>-Membre de la section santé à Radio Vision 2000</p>
            <h4>R&eacute;f&eacute;rences</h4>
            <p>-Dr Reginald Boulos: PDG CDS</p>
            <p>-Dr Fritz Moise: PDG FOSREF</p>
            <p>-Dr Vladimir Larsen: Président de la Société Haitienne d'OBGYN</p>
            <p>-Dr Khalil Zouiten: PDG de ANTECHO (Antilles-Echographie, Fort de France-Martinique)</p>
            <p>-Dr Charles Quist: Clinique Ste Marie (Centre martiniquais d'infertilité, Fort de France)</p>
            <p>-Dr René Frydman: Pionnier de la fecondation in vitro en France à Hôpital Antoine Béclère, Paris, France</p>
          </div> 
        </div>
        
      </section>
      <div className="clinic white color-1-text">
        <h3 className="center">La Clinique</h3>
        <h5 className="center">Située à Montagne Noire, non loin de Port-au-Prince, la capitale d'Haiti, Villa Sante vous offre un cadre attirant où vous vous sentirez à l'aise</h5>
        <div className="clinic-slider">
          <Slider1 />
        </div>
        <div className="clinic-cta center">
          <Link 
            to="/contact" 
            id="about-cta" 
            className="right color-1 color-0-text cta z-depth-5" 
            onMouseEnter={() => gsap.to("#about-cta", {scale: 1.1, ease: "power4.out"})}
            onMouseLeave={() => gsap.to("#about-cta", {scale: 1, ease: "power4.out"})}
          >
            Passez nous voir!
          </Link>
        </div>    
      </div>
      
    </div>
  </Layout>
)}

export default About