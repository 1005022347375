import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'

const BackgroundSection = ({ className }) => {


  return (
    <StaticQuery query={graphql`
      query {
        desktop: file(relativePath: { eq: "slider/5.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
     render={data => {
       // Set ImageData.
       const imageData = data.desktop.childImageSharp.fluid
       return (<BackgroundImage Tag="section"
                           className={className}
                           fluid={imageData}
                           backgroundColor={`#040e18`}
          >
            
          </BackgroundImage>
       )
     }
     }
    />
  )
}

const StyledBackgroundSection = styled(BackgroundSection)`
  // position: sticky !important;
  // top: 0;
  // width: 50vw;
  // height: 100vh;
  // opacity: 0.5 !important;
  // background-image: linear-gradient(to right bottom, #1a237e, #182070, #161e62, #151b54, #141846);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
`

export default StyledBackgroundSection